<script setup>

const backend = useBackend();
const route = useRoute();
const {breakpoint} = useBreakpoints();
const badRoutes = useBadRoutes();
const calculator = useCalculator();
const priceExpand = ref(false);
const pricesExpanded = computed(() => {
  if (breakpoint.value !== 'sm') return true;
  return priceExpand.value;
})

const hash = ref(route.hash);

const {data: cars, error} = await useAsyncData(() => new Promise(async (resolve, fail) => {

  // if(!route.params.brands || !route.params.model) resolve([]);

  const cars = await backend.getModelBodyTypes(
    route.params.brand,
    route.params.model,
    true
  );

  if (!cars?.data.length) {
    return fail([]);
  }

  cars.data.sort(car => car.count === 0 ? 1 : -1);
  resolve(cars);
}))

if (error.value) {
  // not works on dev, only on production!
  await badRoutes.handle()
}

const brand = computed(() => cars ? cars.value.data[0].brandName : "");
const model = computed(() => cars ? cars.value.data[0].modelName : "");

const {data: description} = await useAsyncData(() => new Promise(async resolve => {
  const result = await backend.getModelDescription(route.params.model);
  resolve(result.data)
}))

const selectedCar = ref(cars.value.data[0]);

const recalculateInstallments = () => {
  calculator.getInstallment(cars.value);
}

const changeView = (body) => {
  selectedCar.value = cars.value.data.find(car => car.bodyType.toLowerCase() === body);
  hash.value = `#${body}`
}

onMounted(() => {

})

useHead({
  title: `${brand.value.toUpperCase()} ${model.value.toUpperCase()} leasing - Wybierz i sfinansuj online!`,
  meta: [
    {name: 'description', content: `${brand.value.toUpperCase()} ${model.value.toUpperCase()} w leasingu dla przedsiębiorców i konsumentów ✔️ Decyzja leasingowa w 15 min ✔️ Atrakcyjne rabaty ✔️ Sprawdź!`}
  ],
  link: [
    {rel: 'canonical', href: 'https://vehis.pl' + route.path}
  ]
});

</script>

<template>
  <div class="sticky top-[97px] md:top-[111px] z-50">
    <Container no-paddings gray>
      <MenuScrolled>
        <AnchorJump>Finansowanie</AnchorJump>
        <AnchorJump to="showcase" v-if="description?.blocks">Poznaj ten model</AnchorJump>
        <AnchorJump to="gallery" v-if="description?.blocks">Galeria</AnchorJump>
        <AnchorJump to="driveplus">Pakiet DRIVE+</AnchorJump>
        <AnchorJump to="gains">Korzyści oferty</AnchorJump>
      </MenuScrolled>
    </Container>
  </div>

  <Container no-paddings>
    <div class="shadow-box -mx-4 sm:-mx-8 bg-gray-100">
      <div class="bg-gradient-to-t from-30% from-white via-gray-200 via-40% to-white to-100%">
        <div class="px-6 sm:px-8 overflow-hidden">
          <CarPreview :header="`Leasing ${brand} ${model}`"
                      :cars="cars.data"
                      @change="(body) => changeView(body)"
          />
        </div>
      </div>
      <Accordion :expanded="!!selectedCar.count">
        <div class="px-6 py-4 sm:px-8 bg-white flex flex-col md:flex-row md:w-full md:gap-4 md:divide-x">
          <Accordion :expanded="pricesExpanded" class="order-3 md:order-1 md:block md:grow">
            <div class="pb-1">
              <CarFinancing class=" md:block md:grow " @change="recalculateInstallments" />
            </div>
          </Accordion>
          <CarPrices class="md:w-[166px] xl:w-[308px] md:pl-4 md:flex-col xl:flex-row md:items-end order-1 md:order-2"
                     :catalogPrice="selectedCar.basic"
                     :price="selectedCar.price"
                     :installment="selectedCar.installment"
          />
          <div class="md:hidden ml-auto w-fit order-2 md:order-3">
            <ButtonPlain class="w-fit" small @click="priceExpand = !priceExpand">
              <template v-slot:leftSlot>
                <IconEdit class="!w-5 !h-5 stroke-azure" />
              </template>
              <template v-slot:default>Dopasuj</template>
            </ButtonPlain>
          </div>
        </div>
      </Accordion>
    </div>
  </Container>
  <Container>

    <LayoutColumns>
      <LayoutMainColumn noflex>
        <div class="flex flex-col gap-6" v-if="description.blocks">
          <a id="showcase"></a>
          <ModelBanner :photos="description.mainPhotos" />

          <template v-for="(block, idx) in description.blocks.filter(b => b.show)">
            <div v-if="block.header"> {{ block.header }}</div>
            <ModelBlock :boxes="block.boxes.filter(b => b.show).length">
              <template v-for="box in block.boxes.filter(b => b.show)">
                <ModelBox :data="box" />
              </template>
            </ModelBlock>

            <ModelCallUs v-if="!((idx + 1) % 2)"
                           :label="`Chcesz dowiedzieć się więcej? Zadzwoń!`"
                           button-text="+ 48 22 103 39 74"
                           :href="{name: 'cars', hash: hash, params: {brand: brand.toLowerDashed(), model: model.toLowerDashed()}}"
            />
          </template>
          <a id="gallery"></a>
          <CarGallery class="mt-4" :photos="description.gallery" v-if="description.gallery" />
          <ModelShowCars :label="`${brand} ${model} to model dla Ciebie?`"
                         button-text="Zobacz dostępne samochody"
                         :href="{name: 'cars', hash: hash, params: {brand: brand.toLowerDashed(), model: model.toLowerDashed()}}"
          />
        </div>
        <div class="mb-10">
            <a id="driveplus"></a>

            <BlockDrivePlus :no-assistance="!selectedCar?.isPassenger"  title="Ten samochód chroni pakiet DRIVE+"/>
          </div>
        <div>
          <a id="gains"></a>
          <SubContentHeader>Korzyści oferty VEHIS</SubContentHeader>
          <StaticGains />
        </div>
      </LayoutMainColumn>
      <LayoutSideColumn>
        <FormContact class="!top-44" :car="selectedCar" />
      </LayoutSideColumn>
    </LayoutColumns>
    <div>
      <SeoModel :car="selectedCar" />
    </div>
  </Container>
  <Container with-border-top>
    <SeoBrandsCloud />
  </Container>
</template>
