<script setup>
const slots = defineSlots();

const props = defineProps({
  boxes: Number
})

const refSlideNavigation = ref();
const refSlideControl = ref();

const updateSlideNumber = (slideNumber) => {
  refSlideNavigation.value.setSlide(slideNumber);
}

const scrollToSlide = (slide) => {
  refSlideControl.value.scrollToSlide(slide);
}

</script>

<template>
  <div v-if="boxes < 3"
       class="grid gap-6 grid-cols-1 md:grid-cols-2"
       :class="[{'md:!sgrid-cols-1': boxes === 1}]"
  >
    <slot />
  </div>
  <div v-else class="w-full this-is-slide-control">
    <SlideControl ref="refSlideControl"
                  @swipeEnd="(slideNumber) => updateSlideNumber(slideNumber)">
      <template v-for="box in slots.default()[0].children" :key="box">
        <SlideItem>
          <Component :is="box" />
        </SlideItem>
      </template>
    </SlideControl>

    <SlideNavigation ref="refSlideNavigation"
                     class="-mt-4"
                     :items=boxes
                     @changed="(slide) => scrollToSlide(slide)"
    />
  </div>
</template>
