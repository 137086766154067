<script setup>
const route = useRoute();
const backend = useBackend();

const props = defineProps({
  car: Object,

})

const carModelId = computed(() => props.car.modelId);

const {data: seo, refresh: modelSeoRefresh} = await useAsyncData(() => {
  return new Promise(async success => {

    if (carModelId.value) {

      const result = await backend.getModelSeo(carModelId.value);

      let seoData = {}

      if (result.error) {
        seoData.available = false;
      } else {
        if (!result.data.header) {
          seoData.available = false;
        } else {
          seoData = result.data;
          seoData.available = true;
        }
      }

      success(seoData)

    } else {
      success({available: false});
    }
  })
})

const {data: models, refresh: refreshModels} = await useAsyncData(() => new Promise(async success => {

  if (!route.params.brand || !route.params.model) {
    return success([]);
  }

  const result = await backend.getModels({brand: route.params.brand});
  result.data.sort((a, b) => a.modelName > b.modelName ? 1 : -1);
  // result.data.map(i => ({brandName: i.brandName, modelName: i.modelName}));
  const models =  [ ... new Set(result.data.map(i => i.modelName)).values()]
  success(models);
}));
</script>

<template>
  <template v-if="seo.available">

    <div class="!font-extralight">
      <div class="h-0 my-8 border-t border-gray-400 w-auto"></div>
      <h2 class="text-lg font-normal text-center mb-8 mt-12">{{ seo.header }}</h2>
      <div class="text-sm font-light sm:col-span-2" v-html="seo.description">
      </div>


      <div class="h-0 my-8  border-t border-gray-400 w-auto"></div>

      <h2 class="text-lg font-normal  text-center mb-8 mt-12">{{ seo.subheader }}</h2>

      <div class="grid grid-cols-1 md:grid-cols-3 gap-8">

        <div class="text-sm font-light" v-html="seo.col1">

        </div>

        <div class="text-sm font-light" v-html="seo.col2">

        </div>

        <div class="text-sm font-light" v-html="seo.col3">

        </div>

      </div>
    </div>

    <div class="px-4 sm:px-20 pt-10">
      <div class="font-light text-sm text-gray-600 ">
        <div class="text-center mb-2">Sprawdź ofertę leasingową dla pozostałych modeli {{ car.brandName }}:</div>
        <div class="text-center">
          <template v-for="(model,index) in models">
            <NuxtLink
              class="underline"
              :to="{
            name: 'model',
            params: {
              brand: car.brandName.toLowerDashed(),
              model: model.toLowerDashed()
            }
          }">
              {{ car.brandName }}&nbsp;{{ model }}
            </NuxtLink>
            {{ index < models.length - 1 ? ", " : "" }}
          </template>
        </div>
      </div>
    </div>

  </template>
</template>
